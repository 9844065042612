import React, {useEffect, useState} from "react"

import {ButtonGroup, Button, Form} from "react-bootstrap"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Link, useIntl} from "gatsby-plugin-intl"
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import nl from 'date-fns/locale/nl';

const Step2 = ({nextStep, handleChange, state}) => {

  const intl = useIntl();

  return (
    <div>
      <div className="white-container mb-5">

        <h1 className="with-border-top with-border-bottom text-uppercase">{intl.formatMessage({ id: "1_1_1_1_login_sign_up_step2_address.title" })}</h1>

        <p className="mb-3">{intl.formatMessage({ id: "1_1_1_1_login_sign_up_step2_address.description" })}</p>

        <form className="row" onSubmit={(event) => nextStep(event)}>

          <div className="col-md-8 pt-2">
            <Form.Group>
              <Form.Label className="required text-uppercase">{intl.formatMessage({ id: "generic.forms.form_field_labels.street_name" })}</Form.Label>
              <Form.Control type="text" name="street_name" onChange={handleChange}/>
            </Form.Group>
          </div>

          <div className="w-100"></div>

          <div className="col-md-4">
            <Form.Group>
              <Form.Label className="required text-uppercase">{intl.formatMessage({ id: "generic.forms.form_field_labels.house_number" })}</Form.Label>
              <Form.Control type="number" name="house_number" onChange={handleChange}/>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group>
              <Form.Label className="text-uppercase">{intl.formatMessage({ id: "generic.forms.form_field_labels.house_number_ext" })}</Form.Label>
              <Form.Control type="text" name="house_number_ext" onChange={handleChange}/>
            </Form.Group>
          </div>
          <div className="w-100"></div>
          <div className="col-md-4">
            <Form.Group>
              <Form.Label className="required text-uppercase">{intl.formatMessage({ id: "generic.forms.form_field_labels.postal_code" })}</Form.Label>
              <Form.Control type="text" name="postal_code" onChange={handleChange}/>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group>
              <Form.Label className="required text-uppercase">{intl.formatMessage({ id: "generic.forms.form_field_labels.city" })}</Form.Label>
              <Form.Control type="text" name="city" onChange={handleChange}/>
            </Form.Group>
          </div>

          <div className="w-100"></div>


          <div className="col-md-4">
            <div className="required-field mb-4">*{intl.formatMessage({ id: "generic.forms.form_mentions.required_fields" })}</div>
          </div>

          <div className="col-md-4 ml-md-auto">
            <button type="submit" className={"button red mx-auto mr-md-0 " + (state && state.nextButtonDisabled ? "disabled" : "")}>{intl.formatMessage({ id: "generic.nav_buttons.next_button" })}</button>
          </div>

        </form>
      </div>

      <p className="text-center">{intl.formatMessage({ id: "generic.forms.form_mentions.already_member" })}</p>

      <div className="mb-4 text-center">
        <Link to="/sessions/login" className="button white mx-auto">{intl.formatMessage({ id: "generic.forms.form_buttons.signin" })}</Link>
      </div>

    </div>
  )
}


export default Step2
